@import './variables';
.root,
.selectedRoot {
    border: 1px solid $button-light;
    border-radius: 8px;
    padding: 16px;
}
.selectedRoot {
    border: 1px solid $button-medium;
    background-color: $section-medium;
    .iconcontainer {
        background-color: $primary;
        align-self: center;
    }
}
.iconRow {
    display: flex;
    align-items: center;
    align-items: flex-start;
    gap: 16px;
    min-width: 100%;
}
.iconTitle {
    display: flex;
    min-width: 100%;
    gap: 16px;
}
.iconcontainer {
    height: 50px;
    width: 50px;
    min-width: 50px;
    border-radius: 100px;
    background-color: $section-medium;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.check {
    display: flex;
    justify-content: space-between;
}
.checkmarkContainer {
    display: flex;
    width: 24px;
    height: 24px;
    min-width: 24px;
    background-color: $button-medium;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
}
.checkbox {
    padding-top: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
}
.label {
    display: flex;
    gap: 4px;
    padding-bottom: 8px;
    color: $button-medium;
    .required {
        color: $important;
    }
}
.input {
    max-width: 100%;
    padding: 12px 16px;
    background-color: $primary;
    border-radius: 12px;
    border-width: 0;
    font-family: $body-font-family;
    &:focus {
        outline: 1px solid $button-light;
    }

    &::placeholder {
        color: $text-on-primary;
    }
}
