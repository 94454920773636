@import './variables';

// Mixin to manage responsive breakpoints
@mixin screen($breakpoint) {
    // If breakpoint exists in the variables array "variables.scss"
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media only screen and #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }
    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

@mixin line-clamp($lines-to-show: 1) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
}
