@import './variables';

h1,
h2,
h3,
.h1,
.h2,
.h3 {
    font-weight: 700;
}
h1,
.h1 {
    font-size: 48px;
    line-height: 53px;
    letter-spacing: 0.96px;
}
h2,
.h2 {
    font-size: 38px;
    line-height: 46px;
    letter-spacing: 0.76px;
}
h3,
.h3 {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.56px;
}
h4,
.h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
}

p,
.p,
.p_strong,
.p_link {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}

.small,
.small_strong,
.small_link {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}
.small_strong {
    font-weight: 700;
}
.small_link {
    font-weight: 700;
    text-decoration-line: underline;
    color: inherit;
}
.tag,
.form {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
}
.form {
    font-weight: 400;
}

