$primary: #ebe9e8; //ivory
$text-on-primary: #1d2525; //green900

$section-light: #e2e7e0; //green50
$section-medium: #ccd2ca; //green100

$button-light: #758974; //green300
$button-medium: #455d54; //green500
$button-dark: #2d3938; //green700

$button-inactive: #a6a9a8;
$inactive: #898dbc;

$important: #bd1b1b;
$stars: #eeba36;

$alert-success: #dff0d8;
$text-on-alert-success: #3c763d;
$alert-warning: #fcf8e3;
$text-on-alert-warning: #8a6d3b;
$alert-error: #f2dede;
$text-on-alert-error: #af2422;
$alert-info: #daeaf4;
$text-on-alert-info: #2e6480;

$heading-font-family: 'Playfair Display', Arial, sans-serif;
$body-font-family: 'DM Sans', Arial, sans-serif;
$h4-font-family: 'Work Sans', Arial, sans-serif;

$mobile-width: 350px;

// Breakpoints
$breakpoints: (
    'big-desktop': (
        min-width: 1790px,
    ),
    'medium-desktop': (
        min-width: 1366px,
    ),
    'desktop': (
        min-width: 1024px,
    ),
    'tablet': (
        min-width: 768px,
    ),
    'xsmall-tablet': (
        min-width: 580px,
    ),
    'small-mobile': (
        min-width: 360px,
    ),
) !default;
