$icons-font: "icons";

@font-face {
    font-family: $icons-font;
    src: url("../../public/assets/fonts/icons/icons.eot?849d3d1973fe7c85022e92c1626bac18#iefix") format("embedded-opentype"),
url("../../public/assets/fonts/icons/icons.woff2?849d3d1973fe7c85022e92c1626bac18") format("woff2"),
url("../../public/assets/fonts/icons/icons.woff?849d3d1973fe7c85022e92c1626bac18") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
    "chevron-down": "\f101",
    "close": "\f102",
    "editClient": "\f103",
    "instagram": "\f104",
    "minus": "\f105",
    "plus": "\f106",
    "x": "\f107",
);

.icon-chevron-down:before {
    content: map-get($icons-map, "chevron-down");
}
.icon-close:before {
    content: map-get($icons-map, "close");
}
.icon-editClient:before {
    content: map-get($icons-map, "editClient");
}
.icon-instagram:before {
    content: map-get($icons-map, "instagram");
}
.icon-minus:before {
    content: map-get($icons-map, "minus");
}
.icon-plus:before {
    content: map-get($icons-map, "plus");
}
.icon-x:before {
    content: map-get($icons-map, "x");
}
