@import './mixins';

.primary,
.disabled {
    display: flex;
    height: 48px;
    max-width: 560px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border-radius: 12px;
    text-decoration: none;
    cursor: pointer;
    &:disabled {
        background-color: $inactive;
    }
    background-color: $button-medium;
    color: $primary;
    &:hover {
        background-color: $button-dark;
    }
}
.disabled {
    background-color: rgb(69, 93, 84, 0.4);
    &:hover {
        background-color: rgb(69, 93, 84, 0.4);
    }
}
.secondary,
.footerbtn {
    display: flex;
    height: 48px;
    max-width: 100%;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border-radius: 12px;
    text-decoration: none;
    cursor: pointer;
    &:disabled {
        background-color: $inactive;
    }
    color: $button-medium;
    border: 1.5px solid $button-medium;
    &:hover {
        color: $button-dark;
        border: 1.5px solid $button-dark;
    }
}
.footerbtn {
    @include screen('desktop') {
        background-color: transparent;
        color: $primary;
        border: 1.5px solid $primary;
        max-width: 400px;
    }
}