@import './mixins';

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    max-width: 100%;
    width: 100%;
    height: 250px;
    border-radius: 12px;
    border: 2px dashed var(--Green-300, $button-light);
    background-color: white;
    cursor: pointer;
}
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 20px;

    .row {
        display: flex;
        gap: 4px;
    }
}
.required {
    color: $important;
}
.img {
    object-fit: cover;
    max-width: 100%;
    border-radius: 12px;
    cursor: pointer;
}
