.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    // background: $button-medium;
    height: 1px;
    bottom: -20px !important;
    span {
        background: rgba(235, 233, 232, 0.5);
    }
}
.swiper-button-prev::after,
.swiper-button-next::after {
    size: 44px;
    color: $button-medium;
}
