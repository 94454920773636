@import './variables';

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

.no-scroll {
    overflow: hidden !important;
}
body,
main {
    overflow-x: hidden;
    position: relative;

    &:has(.bodyNoScroll) {
        overflow: hidden;
    }
}

.overlay {
    overflow: hidden !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 1000000%;
    height: 100000%;
    background-color: rgba(49, 50, 52, 0.8) !important;
    z-index: 1000;
    overflow-x: hidden;
    input,
    select,
    textarea,
    #progressempty {
        color: rgba(35, 40, 40, 0.05);
        background-color: rgba(40, 51, 44, 0.3) !important;
    }
    button,
    #progressfill {
        color: rgba(164, 169, 161, 0.4);
        background-color: rgba(8, 32, 29, 0.6) !important;
    }
    label,
    input::placeholder,
    textarea::placeholder {
        color: rgba(31, 35, 35, 0.822) !important;
    }
}

.defaultButton {
    cursor: pointer;
    display: flex;
    height: 48px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 12px;
    border-color: transparent;
}

.primaryButton {
    @extend .defaultButton;
    background: $button-medium;
    color: $primary;
}

.secondaryButton {
    @extend .defaultButton;
    background-color: transparent;
    border: 1.5px solid $button-medium;
    color: $button-medium;
}

.largeContainerWrap {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    @include screen('big-desktop') {
        max-width: 1520px;
    }
}

.containerWrap {
    max-width: 600px;
    margin: 0 auto;
}

.sectionWrap {
    padding: 0 20px;
    @include screen('tablet') {
        padding: 0 24px;
    }
    @include screen('desktop') {
        padding: 0 120px;
    }
}

.glPad {
    padding-inline: 20px;
    @include screen('medium-desktop') {
        padding-inline: 0px;
    }
}

.rfqContainer {
    margin: 0 auto;
    max-width: 600px;
    @include screen('tablet') {
        margin: 24px auto 0;
    }
}
