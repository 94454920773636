@import './mixins';

.root {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .form {
        max-width: 100%;
        width: 600px;
        border: 0;
        .input {
            display: flex;
            flex-direction: column;
            padding-top: 24px;
            gap: 8px;
            position: relative;
            .field,
            .inactiveField {
                // max-width: 560px;
                padding: 12px 16px;
                background-color: $section-medium;
                border-radius: 12px;
                border-width: 0;
                font-size: 16px;
                &:focus {
                    outline: 1px solid $button-light;
                }
                &::placeholder {
                    color: $button-inactive;
                }
            }
            .invalidField {
                outline: 1px solid $important;
            }
            .select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                color: $text-on-primary;
            }
            .label {
                color: $button-medium;
            }
            .inactiveLabel {
                color: #84948d;
            }
            textarea {
                font-family: $body-font-family;
                color: $text-on-primary;
                font-size: 16px;
            }
            .inactiveField {
                background-color: #dce0da;
            }
        }

        .selectedText {
            textarea {
                color: $text-on-primary !important;
                font-size: 16px;
            }
        }

        .invalid {
            .invalidLabel {
                color: $important;
            }
        }
        .checkbox {
            padding-top: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }
    }
    .recovery {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'],
    input[type='search'],
    input[type='tel'],
    input[type='color'] {
        font-size: 16px;
    }
}
.required {
    padding-left: 4px;
    color: $important;
}
.charCount {
    color: $button-light;
}

.inactiveRequired {
    padding-left: 4px;
    color: #d16b6b;
}

.row {
    display: flex;
    gap: 8px;
    max-width: 100%;
    align-items: center;
    .title {
        color: $button-dark;
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0.56px;
    }
}

.checkmarkGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .checkmarkRow {
        display: flex;
        gap: 8px;
        align-items: center;
    }
}
.characterCount {
    display: flex;
    justify-content: space-between;
}
.submitButton {
    cursor: pointer;
    margin-top: 32px;
    margin-bottom: 26px;
    padding: 14px 28px;
    // max-width: 400px;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 12px;
    text-decoration: none;
    color: $primary;
    background-color: $button-medium;
    border: none;
    // TODO Use style from typography
    font-family: $body-font-family;
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
}
.disabled {
    background-color: $button-inactive;
}
.eye {
    align-self: flex-end;
    padding-right: 20px;
    position: absolute;
    padding-top: 38px;
}

.invalidLabel {
    color: $important;
}
